import React from 'react';

import publications from "../data/papers";

import Main from '../layouts/Main';
import Helmet from "react-helmet";

const Publications = () => (
    <Main>
        <Helmet>
            <title>Publications | Thomas Wells</title>
        </Helmet>

        {[...publications].reverse().map((p, index) => (
            <section key={index} className={"publication"} id={"publications"}>
                <header>
                    <div className={"title"}>
                        <h2>{p.title}</h2>
                    </div>
                    <div className={"meta"}>
                        <time className={"published"}>{p.month}, {p.year}</time>
                        <p className={"author"}><span className={"name"}>{p.author}</span></p>
                    </div>
                </header>
                <p>{p.abstract}</p>
                <footer>
                    <ul className={"actions"}>
                        {
                            p.files.map((f, key) => {
                                return <li key={key}><a target="_blank" rel="noreferrer" href={f.url} className="button fit">{f.type}</a></li>
                            })
                        }
                    </ul>

                    <ul className="stats">
                        <li>{p.conference} {p.year} - {p.location}</li>
                    </ul>
                </footer>
            </section>
        ))}
    </Main>
);

export default Publications;