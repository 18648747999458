const publications = [
    {
        "title": "CollabAR - Investigating the Mediating Role of Mobile AR Interfaces on Co-Located Group Collaboration",
        "author": "T. Wells & S. Houben",
        "abstract": "Mobile Augmented Reality (AR) technology is enabling new applications for different domains including architecture, education or medical work. As AR interfaces project digital data, information and models into the real world, it allows for new forms of collaborative work. However, despite the wide availability of AR applications, very little is known about how AR interfaces mediate and shape collaborative practices. This paper presents a study which examines how a mobile AR (M-AR) interface for inspecting and discovering AR models of varying complexity impacts co-located group practices. We contribute new insights into how current mobile AR interfaces impact co-located collaboration. Our results show that M-AR interfaces induce high mental load and frustration, cause a high number of context switches between devices and group discussion, and overall leads to a reduction in group interaction. We present design recommendations for future work focusing  collaborative AR interfaces.",
        "thumbnail": "images/thumbs/collabar.jpg",
        "month": "July",
        "year": "2020",
        "conference": "CHI",
        "location": "Honolulu, Hawaii",
        "files": [
            {
                "type": "pdf",
                "url": "papers/CollabARCHI2020.pdf",
            },
            {
                "type": "video",
                "url": "https://youtu.be/uC0DAWEPgXo",
            },
            {
                "type": "doi",
                "url": "https://doi.org/10.1145/3313831.3376541",
            },
        ]
    },
    {
        "title": "A Study into the Effect of Mobile Device Configurations on Co-Located Collaboration using AR",
        "author": "T. Wells, D. Potts, & S. Houben",
        "abstract": "The increasing availability of portable handheld mobile Augmented Reality technology is revolutionising the way digital information is embedded into the real world. As this data is embedded, it enables new forms of cross-device collaborative work. However, despite the widespread availability of handheld AR, little is known about the role that device configurations and size play on collaboration. This paper presents a study that examines how completing tasks using a simple mobile AR interface on different device sizes and configurations impacts key factors of collaboration such as collaboration strategy, behaviour, and efficacy. Our results show subtle differences between device size and configurations that have a direct influence on the way people approach tasks and interact with virtual models. We highlight key observations and strategies that people employ across different device sizes and configurations. ",
        "thumbnail": "images/thumbs/devicear.png",
        "month": "September",
        "year": "2022",
        "conference": "MobileHCI",
        "location": "Vancouver, Canada",
        "files": [

        ]
    },
];

export default publications;

