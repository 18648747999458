import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import teaching from "../data/teaching";
import Helmet from "react-helmet";

import Main from '../layouts/Main';

const Teaching = () => (
    <Main>
        <Helmet>
            <title>Teaching | Thomas Wells</title>
        </Helmet>
        <article className="post" id="teaching">
            <header>
                <div className="title">
                    <h2>Teaching</h2>
                </div>
            </header>
            <Tabs>
                <TabList>
                    <Tab><h4>All Teaching</h4></Tab>
                    <Tab><h4>Undergraduate</h4></Tab>
                    <Tab><h4>Postgraduate</h4></Tab>
                    <Tab><h4>Supervision</h4></Tab>
                </TabList>

                <TabPanel>
                    <table>
                        <tbody>
                        {
                            teaching.reverse().map((t, index) => {
                                if(t.degree !== "Supervision") {
                                    return(
                                        <tr key={index}>
                                            <td>
                                                <h3>{t.module}</h3>
                                                <p className={"published"}> {t.university} ({t.year})</p>
                                            </td>
                                        </tr>
                                    )
                                }
                                return ''
                            })
                        }
                        </tbody>
                    </table>
                </TabPanel>
                <TabPanel>
                    <table>
                        <tbody>
                        {
                            teaching.reverse().map((t, index) => {
                                if(t.degree === "Undergraduate") {
                                    return(
                                        <tr key={index}>
                                            <td>
                                                <h3>{t.module}</h3>
                                                <p className={"published"}> {t.university} ({t.year})</p>
                                            </td>
                                        </tr>
                                    )
                                }
                                return ''
                            })
                        }
                        </tbody>
                    </table>
                </TabPanel>

                <TabPanel>
                    <table>
                        <tbody>
                        {
                            teaching.reverse().map((t, index) => {
                                if(t.degree === "Postgraduate") {
                                    return(
                                        <tr key={index}>
                                            <td>
                                                <h3>{t.module}</h3>
                                                <p className={"published"}> {t.university} ({t.year})</p>
                                            </td>
                                        </tr>
                                    )
                                }
                                return ''
                            })
                        }
                        </tbody>
                    </table>
                </TabPanel>


                <TabPanel>
                    <table>
                        <tbody>{
                            teaching.reverse().map((t, index) => {
                                if(t.degree === "Supervision") {
                                    return(
                                        <tr key={index}>
                                            <td>
                                                <h3>{t.module}</h3>
                                                <p className={"published"}> {t.university} ({t.year})</p>
                                            </td>
                                        </tr>
                                    )
                                }
                                return ''
                            })
                        }
                        </tbody>
                    </table>
                </TabPanel>
            </Tabs>
        </article>
    </Main>
);

export default Teaching;