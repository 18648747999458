import { faLinkedinIn, faOrcid, faTwitter, faGoogle } from '@fortawesome/free-brands-svg-icons'

const data = [
    {
        link: "https://twitter.com/wellsthomas",
        label: "Twitter",
        icon: faTwitter,
    },
    {
        link: "https://linkedin.com/thomasjwells",
        label: "LinkedIn",
        icon: faLinkedinIn,
    },
    {
        link: "https://orcid.org/0000-0002-5975-9535",
        label: "OrcID",
        icon: faOrcid,
    },
    {
        link: "https://scholar.google.co.uk/citations?user=Ev2t9z4AAAAJ&hl=en",
        label: "Google Scholar",
        icon: faGoogle,
    }
];

export default data;