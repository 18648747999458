import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

// Views
import Index from './views/Index';
import Publications from './views/Publications';
import Awards from './views/Awards';
import Teaching from './views/Teaching';

// Styling
import './static/sass/main.scss';

ReactDOM.render(
    <Router>
        <Switch>
            <Route exact path="/" component={Index}/>
            <Route exact path="/publications" component={Publications}/>
            <Route exact path="/awards" component={Awards}/>
            <Route exact path="/teaching" component={Teaching}/>
        </Switch>
    </Router>,
    document.getElementById('root')
);