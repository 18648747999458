import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import data from '../../data/contact';
import publications from '../../data/papers';

const Nav = () => (
    <section id="sidebar">
        <section id="intro">
            <Link to="/" className="logo">
                <img src={`/images/me.jpg`} alt="" />
            </Link>
            <header>
                <h2>Thomas Wells</h2>
                <p><a target="_blank" rel="noreferrer" href="mailto:t.wells@lancaster.ac.uk">t.wells@lancaster.ac.uk</a></p>
            </header>
        </section>

        <section>
            <ul className="posts">
                <h2>Latest Publication</h2>
                <Link to={"/publications"}>
                        {[...publications].reverse().slice(0,1).map((p, index) => (
                        <li>
                            <article>
                                <header>
                                    <h3>{p.title}</h3>
                                    <time className="published">{p.conference} {p.year}</time>
                                </header>
                                <img className={"image"} src={p.thumbnail} alt="" />

                            </article>
                        </li>
                    ))}
                </Link>
            </ul>
        </section>

        <section id="footer">
            <ul className="icons">
                {data.map((s) => (
                    <li key={s.label}>
                        <a target="_blank" rel="noreferrer" href={s.link}>
                            <FontAwesomeIcon icon={s.icon} />
                        </a>
                    </li>
                ))}
            </ul>
            <p className="copyright">
                &copy; Thomas Wells - <Link to="/">tomwells.co.uk</Link>
            </p>
        </section>
    </section>
);

export default Nav;