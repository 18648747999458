const awards = [
    {
        "icon": "🏆",
        "name": 'Honourable Mention - CollabAR',
        "awardedBy": "Conference on Human Factors in Computing Systems",
        "year": "2020",
    },

];

export default awards;