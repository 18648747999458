import React from 'react';

import Main from '../layouts/Main';

import awards from "../data/awards";
import Helmet from "react-helmet";

const Awards = () => (
    <Main>
        <Helmet>
            <title>Awards | Thomas Wells</title>
        </Helmet>
        <article className="post" id="awards">
            <header>
                <div className="title">
                    <h2>Awards</h2>
                </div>
            </header>
            <table className={"awards-table"}>
                <tbody>
                {
                    awards.map((a, index) => {
                        return (
                            <tr key={index} className={"awards"}>
                                <td width="90%">
                                    <h2>{a.name}</h2>
                                    <p className={"published"}>{a.awardedBy} - {a.year}</p>
                                </td>
                                <td className="award-icon">{a.icon}</td>
                            </tr>
                        );
                    })
                }


                </tbody>
            </table>

            {/*{*/}
            {/*    awards.map(a => {*/}
            {/*        return (*/}
            {/*            <article>*/}
            {/*                <header><h3><a>CollabAR - Investigating the Mediating Role of Mobile AR Interfaces on*/}
            {/*                    Co-Located Group Collaboration</a></h3>*/}
            {/*                    <time className="published">CHI 2020</time>*/}
            {/*                </header>*/}
            {/*                <a className="image"><img className="image" src="images/thumbs/collabar.jpg" alt="" /></a>*/}
            {/*            </article>*/}
            {/*    );*/}
            {/*    })
            }*/}
        </article>
    </Main>
);

export default Awards;