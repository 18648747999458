const data = [
    {
        path: '/',
        label: 'Thomas Wells',
        index: true,
    },
    {
        path: '/publications',
        label: 'Publications',
    },
    {
        path: '/awards',
        label: 'Awards',
    },
    {
        path: '/teaching',
        label: 'Teaching',
    }
];

export default data;