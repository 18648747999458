import React from 'react';

import Main from '../layouts/Main';
import Helmet from "react-helmet";

const Index = () => (
    <Main>
        <Helmet>
            <title>About | Thomas Wells</title>
        </Helmet>
        <article className="post" id="index">
            <header>
                <div className="title">
                    <h2>About Me</h2>
                </div>
            </header>
            <h3>Who am I?</h3>
            <p>
                Hey, I'm Tom 👋. I'm a PhD Candidate in the <a target="_blank" rel="noreferrer" href="https://www.lancaster.ac.uk/scc/research/interactive-systems/">Interactive Systems group</a> at Lancaster University, supervised
                by <a target="_blank" rel="noreferrer" href={"https://stevenhouben.be"}>Dr. Steven Houben</a>. My primary research area is within the
                field of Human-Computer Interaction (HCI), where I research areas around collaboration in Augmented
                Reality. I have particular interests in topics that are related to Augmented and Virtual reality,
                Computer-Supported Cooperative Work, User Interfaces, Interaction Techniques, and Hybrid Workspaces.
            </p>
        </article>
    </Main>
);

export default Index;