const teaching = [
    {
        "module": "SCC.110 - Software Development",
        "university": 'Lancaster University',
        "year": "2019/2020",
        "degree": "Undergraduate",
    },
    {
        "module": "SCC.130 - Information Systems",
        "university": 'Lancaster University',
        "year": "2019/2020",
        "degree": "Undergraduate",
    },
    {
        "module": "SCC.202 - Human-Computer Interaction",
        "university": 'Lancaster University',
        "year": "2019/2020",
        "degree": "Undergraduate",
    },
    {
        "module": "Jordan Young - BSc Project",
        "university": 'Lancaster University',
        "year": "2019/2020",
        "degree": "Supervision",
    },
    {
        "module": "Zhong Hao Lin Chen - MSc Project",
        "university": 'Lancaster University',
        "year": "2019/2020",
        "degree": "Supervision",
    },
    {
        "module": "SCC.402 - Innovations in Interactive Computing",
        "university": 'Lancaster University',
        "year": "2020/2021",
        "degree": "Postgraduate",
    },
    {
        "module": "SCC.110 - Software Development",
        "university": 'Lancaster University',
        "year": "2020/2021",
        "degree": "Undergraduate",
    },
    {
        "module": "Matthew Templeton - EPSRC Intern",
        "university": 'Lancaster University',
        "year": "2020/2021",
        "degree": "Supervision",
    },
    {
        "module": "Fraser Benjamin - BSc Project",
        "university": 'Lancaster University',
        "year": "2020/2021",
        "degree": "Supervision",
    },
    {
        "module": "SCC.203 - Computer Networks",
        "university": 'Lancaster University',
        "year": "2020/2021",
        "degree": "Undergraduate",
    },
    {
        "module": "SCC.402 - Innovations in Interactive Computing",
        "university": 'Lancaster University',
        "year": "2021/2022",
        "degree": "Postgraduate",
    },
];

export default teaching;