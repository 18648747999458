import React from 'react';
import Nav from '../components/template/Nav';

import Header from '../components/template/Header';


const Main = (props) => (
    <>
        <div id="wrapper">
            <Header />
            <div id="main">
                {props.children}
            </div>
            {props.fullPage ? null : <Nav />}
        </div>
    </>
);

export default Main;